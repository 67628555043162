<div class="w-100">
  <table class="table">
    <thead>
    <tr>
      <th *ngFor="let field of fields" class="text-capitalize">{{field.label | titlecase}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of records" class="table-row">
      <td *ngFor="let field of fields">
        <ng-container [ngSwitch]="getCellType(field.type, item[field.key])">
          <ng-container *ngSwitchCase="1">
            <span *ngIf="item[field.key]" [innerHTML]="item[field.key]" class="text-justify"></span>
            <span *ngIf="!item[field.key]"> {{field.defaultValue}}</span>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <div class="expansion-title">{{item[field.key] | truncate: [30]}}</div>
                </mat-expansion-panel-header>
                <span *ngIf="item[field.key]">{{item[field.key]}}</span>
                <span *ngIf="!item[field.key]"> {{field.defaultValue}}</span>
              </mat-expansion-panel>
            </mat-accordion>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <span class="mdi mdi-{{item[field.key]}} action-btn" *ngIf="item[field.key]" (click)="itemClicked(item)"></span>
            <span class="mdi mdi-{{field.defaultValue}} action-btn" *ngIf="!item[field.key]" (click)="itemClicked(item)"></span>
          </ng-container>
          <ng-container *ngSwitchCase="4">
            <div class="table-chip" [style.background-color]="item.secondary" [style.color]="item.primary" *ngIf="item[field.key]">{{item[field.key] | titlecase}}</div>
            <div class="table-chip" *ngIf="!item[field.key]">{{field.defaultValue}}</div>
          </ng-container>

          <ng-container *ngSwitchCase="5">
            <span *ngIf="item[field.key]" [matTooltip]="item[field.key]" class="cursor-pointer text-ellipsis" (click)="field.copyField ? copyToClipBoard(item[field.key]): null">{{item[field.key] | truncate: [15]}}</span>
            <span *ngIf="!item[field.key]" [matTooltip]="field.defaultValue" class="cursor-pointer text-ellipsis" (click)="field.copyField ? copyToClipBoard(field.defaultValue) : null">{{item.defaultValue | truncate: [20]}}</span>
          </ng-container>

          <ng-container *ngSwitchCase="6">
            <span class="mdi mdi-{{item[field.key]}} action-btn" *ngIf="item[field.key]" [matMenuTriggerFor]="optionsMenu"></span>
            <span class="mdi mdi-{{field.defaultValue}} action-btn" *ngIf="!item[field.key]" [matMenuTriggerFor]="optionsMenu"></span>

            <mat-menu #optionsMenu="matMenu">
              <div mat-menu-item *ngFor="let option of menuOption" [disabled]="option.disabled">
                <div (click)="menuClicked(item, option)">{{option.text}}</div>
              </div>
            </mat-menu>
          </ng-container>

          <ng-container *ngSwitchCase="7">
            <div *ngIf="item[field.key]"  class="cursor-pointer"  [innerHTML]="item[field.key]" [matTooltip]="item[field.key]"></div>
            <span *ngIf="!item[field.key]" class="cursor-pointer">{{item.defaultValue | truncate: [20]}}</span>
          </ng-container>

        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="page-container" *ngIf="records && enablePagination">
    <span class="page-number" *ngIf="records.length > 0">{{getPageInfo('start')}} to {{getPageInfo('end')}} of {{getPageInfo('total')}}</span>
    <span class="page-number" *ngIf="records.length === 0"> 0 of {{records.length}}</span>
    <span class="mdi mdi-chevron-left page-icon" (click)="changePage(-1)"></span>
    <span class="mdi mdi-chevron-right page-icon" (click)="changePage(1)"></span>
  </div>
</div>

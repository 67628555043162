import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  isDesktop = window.innerWidth > 780;


  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  close(close: boolean): void {
    this.dialogRef.close({
      accept: close
    });
  }
}

export interface DialogData {
  title: string;
  text: string;
  positiveText?: string;
  negativeText?: string;
  positiveButtonColor?: string;
  negativeButtonColor?: string;
}

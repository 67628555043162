import {environment} from "../environments/environment";
export class AppConstants {

  public static BASE_URL = environment.API_BASE_URL;
  public static API = {
    LOGIN: this.BASE_URL + 'admin/auth/login',
    REFRESH_TOKEN: this.BASE_URL + 'admin/auth/token',
    MY_PROFILE: this.BASE_URL + 'admin/user/me',
    TRANSACTIONS: this.BASE_URL + 'admin/transaction',
    EXPORT_TRANSACTIONS: this.BASE_URL + 'admin/export/transactions',
    MEMBERSHIPS: this.BASE_URL + 'admin/membership',
    GET_ADMIN_USERS: this.BASE_URL + 'admin/user/admin',
    GET_ADMIN_VENUES: this.BASE_URL + 'admin/venue',
    GET_REGULAR_USERS: this.BASE_URL + 'admin/user/regular',
    GET_IMAGES: this.BASE_URL + 'admin/media/gallery',
    GET_BRANDS: this.BASE_URL + 'admin/brand',
    FILE_UPLOAD: this.BASE_URL + 'admin/media',
    PRIVATE_FILE_UPLOAD: this.BASE_URL + 'admin/private'
  }
}

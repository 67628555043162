import { MatCardModule } from '@angular/material/card';
import {TableComponent} from "./table/table.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import {MatMenuModule} from "@angular/material/menu";
import { TruncatePipe } from './truncate.pipe';
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatExpansionModule} from "@angular/material/expansion";
import {AddAdjustmentComponent} from "../pages/add-adjustment/add-adjustment.component";
import {MatRadioModule} from "@angular/material/radio";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatButtonModule} from "@angular/material/button";
import {ConfirmationDialogComponent} from "./confirmation-dialog/confirmation-dialog.component";

@NgModule({
  declarations: [
    TableComponent,
    AddAdjustmentComponent,
    ConfirmationDialogComponent,
    TruncatePipe
  ],
  imports: [CommonModule, RouterModule, FormsModule,
    ReactiveFormsModule, MatCheckboxModule, MatCardModule, MatMenuModule, MatTooltipModule, MatExpansionModule, MatRadioModule, MatFormFieldModule, MatInputModule, MatSlideToggleModule, MatButtonModule],
    exports: [
        TableComponent,
        TruncatePipe
    ],
})
export class SharedModule { }

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {Clipboard} from '@angular/cdk/clipboard';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as _ from 'lodash';
import {SelectOption} from "../../models/SelectOption";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})

/**
 * @author Siddharth Saxena
 * TableComponent
 * This component is supposed to be used in almost all the places
 * where a table is needed, it supports multiple types of cell
 * 1. Text, 2. Icon, 3. Expandable Text 4. Long Hover Text 5. Chip
 */
export class TableComponent implements OnInit, AfterViewInit, OnChanges {
  // table data
  @Input() data: any;

  @Input() menuOption?: SelectOption[] = [];
  @Input() records: any[] = [];
  // enable sort
  @Input() enableSort = true;
  // enable pagination
  @Input() enablePagination = true;
  @Input() searchFields: string[] = [];
  @Input() searchTerm = '';
  // Define fields to be displayed in table
  @Input() fields: TableField[] = [];
  @Output() actionClicked = new EventEmitter<any>(false);
  @Output() menuSelected = new EventEmitter<{item: any, option: SelectOption}>(false);

  @ViewChild(MatSort) sort: MatSort | undefined;
  @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
  displayedColumns: any;
  currentPage = 1;
  total = 10;

  constructor(private clipboard: Clipboard, private toast: MatSnackBar) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.buildFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.buildFields();
  }

  buildFields(){
    this.fields = this.fields.map(f => {
      if (typeof f.type !== 'object'){
        f.type = {primary: f.type, secondary: 1};
      }
      return f;
    });
    this.displayedColumns = this.fields.map(f => f.key);
    if (this.searchTerm.length > 0){
      this.applySearch();
    } else {
      this.records = [...this.data];
    }
    this.paginate(this.currentPage);
  }

  private applySearch(): void {
    this.records = _.filter(this.data, (item: any) => {
     const result =  _.find(this.searchFields, (s: any) => {
        const field = item[s] || '';
        let r = field.toLowerCase().includes(this.searchTerm.toLowerCase());
        r = field.length > 0 ? r : false;
        return r;
      });
     return !!result;
    });
  }

  private paginate(current: number): void {
    const data = this.searchTerm.length > 0 ? [...this.records] : [...this.data];
    const size = 100;
    const page = data.reduce((acc, val, i) => {
      const idx = Math.floor(i / size);
      const p = acc[idx] || (acc[idx] = []);
      p.push(val);
      return acc;
    }, []);
    this.records = page[current - 1];
    this.total = page.length;
  }

  getCellType(field: any, item?: string): number {
    // Decide what to pass
    if (!item){
      return field.secondary || 1;
    } else {
      return field.primary;
    }
  }

  public itemClicked(item: any): void {
    this.actionClicked.emit(item);
  }

  public menuClicked(item: any, option: SelectOption): void {
    this.menuSelected.emit({item, option});
  }

  public copyToClipBoard(text: string): void {
    this.clipboard.copy(text);
    this.toast.open('Text copied to clipboard successfully!', 'Ok', {duration: 2000});
  }

  changePage(direction: number): void {
    if (direction === -1){
      if (this.currentPage > 1){
        this.currentPage --;
      }
    } else {
      if (this.currentPage < this.total){
        this.currentPage ++;
      }
    }
    this.paginate(this.currentPage);
  }

  public getPageInfo(type: string): number {
    const length = this.searchTerm.length > 0 ? this.records.length : this.data.length;
    if (type === 'total'){
      return length;
    } else if (type === 'end'){
      const total = this.currentPage * 10;
      return total < length ? total : length;
    } else {
     return ((this.currentPage - 1) * 10) + 1;
    }
  }
}

export interface TableField {
  label: string;
  key: string;
  type: number | { primary: fieldType, secondary: fieldType };
  actionable: boolean;
  defaultValue: string;
  copyField: boolean;
  sortable: boolean;
}

enum fieldType {
  text = 1,
  expandable = 2,
  icon = 3,
  chip = 4,
  tooltip = 5,
  optionMenu = 6
}

import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ApiService} from "../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'app-add-adjustment',
  templateUrl: './add-adjustment.component.html',
  styleUrls: ['./add-adjustment.component.scss']
})
export class AddAdjustmentComponent implements OnInit {
  bonusAmount = 200;
  title = 'Refund Credit Card';
  amountType = 'Refund Amount HKD'
  amount = 100;
  showCrDrFlag = false;
  buttonTxt = `Refund Credit Card` ;
  txnType = 'credit';
  bonus = false;
  id = '';
  reason = '';

  constructor(private dialogRef: MatDialogRef<AddAdjustmentComponent>, private snack: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiService) {

  }


  close() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.id = this.data.id;
    this.amount = this.data.amount;
    switch (this.data.action){
      case 'refund-cc' :
        // 64b1313bd3d6be899bc48aee
        this.showCrDrFlag = false;
        this.title = 'Refund Credit Card';
        this.amountType = 'Refund Amount HKD';
        this.buttonTxt = `Refund Credit Card (${this.amount}) HKD`;
        break;

      case 'adjustment' :
        // 64ab9ec6baf9c24eeeea3c79
        this.showCrDrFlag = true;
        this.title = 'New Adjustment';
        this.amountType = 'Amount';
        this.buttonTxt = `Create Adjustment (${this.amount}) HKD`;
        break;
    }
  }

  amountUpdated(){
    switch (this.data.action){
      case 'refund-cc' :
        this.buttonTxt = `Refund Credit Card (${this.amount}) HKD`;
        break;

      case 'adjustment' :
        const amt =  this.txnType === 'credit' ? Math.abs(this.amount * 100)
          : (-Math.abs(this.amount * 100))
        this.buttonTxt = `Create Adjustment (${this.txnType === 'debit' ? '-' : '+'} ${amt}) HKD`;
        break;
    }
  }

  createAdjustment(){
    const amt =  this.txnType === 'credit' ? Math.abs(this.amount * 100)
      : (-Math.abs(this.amount * 100))
    this.api.createAdjustment(this.id, amt, this.bonus, this.reason)
      .subscribe(txn => {
        /// this.close();
        this.showMsg('Adjustment posted successfully!');
        this.close();
      })
  }

  createRefund(){
    this.api.createRefund(this.id, this.amount * 100, this.bonus, this.reason).
      subscribe(data => {
      this.showMsg('Amount refunded successfully!');
      this.close();
    });
  }

  showMsg(msg: string){
    this.snack.open(msg, 'OK')
  }


  submit() {
    if (this.reason.trim().length > 0){
      if (this.data.action === 'refund-cc'){
        this.createRefund();
      } else {
        this.createAdjustment();
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ApiService} from "../../services/api.service";
import {SessionService} from "../../services/session.service";
import {Router} from "@angular/router";
import {UtilService} from "../../services/util.service";
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(100)]]
  });

  errors = {
    email : {valid : 'Please enter a valid email', required: 'Please enter an email'},
    password : {valid : 'Please enter a valid password', required: 'Please enter a password'},
  };
  inProgress = false;

  constructor(private fb: FormBuilder, private api: ApiService,
              public session: SessionService, private router: Router, private util: UtilService) { }

  ngOnInit(): void {
    console.log('in login!!')
  }

  onSubmit(): any{
    if (this.loginForm.valid){
      this.inProgress = true;
      this.api.doLogin(this.loginForm.value.email || '', this.loginForm.value.password || '')
        .subscribe(async data => {
        await this.session.setToken(data.token);
        await this.api.setToken();
        await this.session.setRefreshToken(data.refreshToken);
        // this.setProfile();
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(this.session.getToken());
        //  console.log(decodedToken);
        this.session.setUser(decodedToken);
          this.inProgress = false;
          this.router.navigateByUrl('/pages');
        }, error => {
          this.inProgress = false;
          this.util.presentToast('Unable to login, please check id and password!', 0);
      });
    }
  }

  private setProfile() {
    this.api.getOwnProfile().subscribe(data => {
      this.session.setUser(data);
    })
  }
}

<div class="text-center mb-4">
  <div class="mb-5 fs-18 dark"><b>Sign in to your account</b></div>
</div>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <div class="row">
    <div class="col-12">
      <div class="mb-2">
        <div class="input-container w-full">
          <div class="input-label">Email</div>
          <input class="input-grey" placeholder="Enter Email" formControlName="email" type="email">
        </div>
      </div>

      <div class="col-12">
        <div class="mb-2" style="margin-top: 35px;">
          <div class="input-container w-full">
            <div class="input-label">Password</div>
            <input class="input-grey" placeholder="Enter Password" formControlName="password" type="password">
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 mt-3">
      <div class="login-btn-container">
        <div class="mb-4 fs-16"><a class="h-link" routerLink="/auth/forgot-password"><b>Forgot Password?</b></a></div>
        <button mat-raised-button class="btn-primary-app mt-3" type="submit" [disabled]="inProgress">Sign Me In</button>
      </div>
    </div>
  </div>
</form>

import { Injectable } from '@angular/core';
import {User} from "../models/User";
import {ReplaySubject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {AppConstants} from "../AppConstants";
import {LocalStorageService} from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  public auth = new ReplaySubject(1);
  public isLoggedIn = false;
  private token = '';
  private user: any;
  private loading: boolean = false;
  pendingCount = 0;
  constructor(private router: Router, private storage: LocalStorageService,
              private http: HttpClient) {
    // Get all info from localstorage
    this.init();
  }

  /**
   * To be used to set token.
   * @param token - The token post received post login.
   */
  async setToken(token: string): Promise<void> {
    this.token = token;
    await this.storage.setItem('token', token);
    await this.storage.setBoolean('loggedIn', true);
  }

  /**
   * To be used to set token.
   * @param token - The token post received post login.
   */
  async setRefreshToken(token: string): Promise<void> {
    await this.storage.setItem('refreshToken', token);
  }

  /**
   * To be used to get token.
   */
  async getRefreshToken(): Promise<void> {
    await this.storage.getItem('refreshToken');
  }

  /**
   * To be used to set user.
   * @param user - The user object.
   */
  async setUser(user: any): Promise<void> {
    // console.log('setting user **************', user);
    this.user = user;
    this.isLoggedIn = true;
    await this.storage.setObject('user', user);
    this.auth.next(true);
  }

  /** This function can be used to get token */
  getToken(): string {
    return this.token;
  }

  /** This function can be used to get user */
  getUser(): any {
    return this.user;
  }

  /** Logout current user */
  logout(): void {
    this.isLoggedIn = false;
    this.storage.removeItem('user');
    this.storage.removeItem('token');
    this.storage.setBoolean('loggedIn', false);
    this.token = '';
    this.auth.next(false);
    this.router.navigateByUrl('/auth/login');
  }

  /** This function is private and should not be used for anything else than init of session service */
  private init(): void {
    this.isLoggedIn = this.storage.getBoolean('loggedIn');
    this.user = this.storage.getObject('user');
    this.token = this.storage.getItem('token') || '';
    const refreshToken = this.storage.getItem('refreshToken') || '';
    if (refreshToken){
      this.verifyToken(refreshToken);
    } else {
      this.auth.next(this.isLoggedIn);
      this.logout();
    }
  }

  /**
   * This function will check on every reload if saved token is still valid and active
   * @private
   */
  private verifyToken(refreshToken: string) {
    this.http.post<any>(AppConstants.API.REFRESH_TOKEN, {refreshToken}).subscribe(data => {
      // console.log('user data', data);
      if (data) {
        this.token = data.token;
        this.setToken(this.token);
        this.auth.next(this.isLoggedIn);
      } else {
        this.logout();
      }
      // console.log('Token verified :: Session is still active!');
    }, error => {
      this.logout();
      console.log('Session is expired!', error.status);
    });
  }
}

<div class="menu-container">
  <div class="row">
    <div class="col">
      <h5>{{data.title}}</h5>
    </div>
  </div>
  <p class="msg-txt" [innerHTML]="data.text"></p>
  <div class="text-right" *ngIf="isDesktop">
    <button mat-raised-button [style.background-color]="data.positiveButtonColor"
            (click)="close(true)" class="positive-btn" *ngIf="data.positiveText">{{data.positiveText}}</button>
    <button mat-flat-button (click)="close(false)" *ngIf="data.negativeText">{{data.negativeText}}</button>
  </div>

  <div class="text-center" *ngIf="!isDesktop">
    <div class="text-center"><button mat-raised-button [style.background-color]="data.positiveButtonColor"
                                     (click)="close(true)" class="positive-btn" *ngIf="data.positiveText">{{data.positiveText}}</button></div>
    <div class="text-center"><button mat-flat-button (click)="close(false)" *ngIf="data.negativeText">{{data.negativeText}}</button></div>
  </div>
</div>

<!--<div class="w-full">-->
<!--  <h1>This is title</h1>-->
<!--</div>-->

import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AppConstants} from "../AppConstants";
import {SessionService} from "./session.service";
import {User, UserRes} from "../models/User";
import {TransactionRes} from "../models/TransactionRes";
import {Membership} from "../models/MemberShip";
import {AdminUser} from "../models/AdminUser";
import {Brand, Venue} from "../models/Venue";
import {RegularUser} from "../models/RegularUser";
import {ImageFile} from "../models/ImageFile";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  headers: any;
  userId = '';
  exportQuery = '';

  constructor(private http: HttpClient, private session: SessionService) {
    this.session.auth.subscribe(data => {
      if (data) {
        // if (session.getToken())
        this.headers = {Authorization: `Bearer ${session.getToken()}`};
        this.userId = session.getUser()?.sub;
      } else {
        this.headers = {};
      }
    });
  }

  doLogin(email: string, password: string): Observable<any> {
    return this.http.post(AppConstants.API.LOGIN, {email, password});
  }

  async setToken() {
    this.headers = {Authorization: `Bearer ${this.session.getToken()}`};
  }

  getOwnProfile() {
    return this.http.get<User>(AppConstants.API.MY_PROFILE, {headers: this.headers});
  }

  getTxns(query: string, skip: number = 0, limit: number = 100): Observable<TransactionRes> {
    console.log('This is txn', query);
    // &status=!PLACEHOLDER
    this.exportQuery = `?skip=${skip}&limit=${limit}&` + query;
    return this.http.get<TransactionRes>(AppConstants.API.TRANSACTIONS + `?skip=${skip}&limit=${limit}&` + query, {headers: this.headers});
  }

  exportTxns(query: string = this.exportQuery): Observable<Blob> {
    console.log('This is txn', query);
    if (query.endsWith('&')) {
      query = query.slice(0, query.length - 1);
    }
    if (!query.startsWith("?")) {
      query = "?" + query;
    }
    // &status=!PLACEHOLDER
    return this.http.get(AppConstants.API.EXPORT_TRANSACTIONS + query,
      {headers: this.headers, responseType: 'blob'});
  }

  getTxn(id: string): Observable<TransactionRes> {
    return this.http.get<TransactionRes>(AppConstants.API.TRANSACTIONS + '/' + id, {headers: this.headers});
  }

  getMemberShips() {
    return this.http.get<Membership[]>(AppConstants.API.MEMBERSHIPS, {headers: this.headers});
  }

  getVenues(): Observable<Venue[]> {
    return this.http.get<Venue[]>(AppConstants.API.GET_ADMIN_VENUES, {headers: this.headers})
  }

  getAdminUsers(): Observable<AdminUser[]> {
    return this.http.get<AdminUser[]>(AppConstants.API.GET_ADMIN_USERS, {headers: this.headers})
  }

  getRegularUsers(): Observable<RegularUser[]> {
    return this.http.get<RegularUser[]>(AppConstants.API.GET_REGULAR_USERS, {headers: this.headers});
  }

  searchRegularUsers(query: string): Observable<RegularUser[]> {
    return this.http.get<RegularUser[]>(AppConstants.API.GET_REGULAR_USERS + '/' + query, {headers: this.headers});
  }

  getImages(): Observable<ImageFile[]> {
    return this.http.get<ImageFile[]>(AppConstants.API.GET_IMAGES, {headers: this.headers});
  }

  uploadImage(form: FormData): Observable<ImageFile> {
    return this.http.post<ImageFile>(AppConstants.API.FILE_UPLOAD, form, {headers: this.headers})
  }

  uploadPrivateImage(form: FormData): Observable<ImageFile> {
    return this.http.post<ImageFile>(AppConstants.API.PRIVATE_FILE_UPLOAD, form, {headers: this.headers})
  }

  addAdminUser(body: any): Observable<AdminUser> {
    return this.http.post<AdminUser>(AppConstants.API.GET_ADMIN_USERS, body, {headers: this.headers})
  }

  addUser(body: any): Observable<RegularUser> {
    return this.http.post<RegularUser>(AppConstants.API.GET_REGULAR_USERS, body, {headers: this.headers})
  }

  updateUser(body: any, id: string): Observable<RegularUser> {
    return this.http.put<RegularUser>(AppConstants.API.GET_REGULAR_USERS + '/' + id, body, {headers: this.headers})
  }

  updateAdminUser(body: any, userId: string): Observable<AdminUser> {
    return this.http.put<AdminUser>(AppConstants.API.GET_ADMIN_USERS + '/' + userId, body, {headers: this.headers})
  }

  getBrands(): Observable<Brand[]> {
    return this.http.get<Brand[]>(AppConstants.API.GET_BRANDS, {headers: this.headers});
  }

  addVenue(body: any) {
    return this.http.post<Venue>(AppConstants.API.GET_ADMIN_VENUES, body, {headers: this.headers})
  }

  updateVenue(id: string, body: any) {
    return this.http.put<Venue>(AppConstants.API.GET_ADMIN_VENUES + '/' + id, body, {headers: this.headers})
  }

  addBrand(body: any) {
    return this.http.post<Brand>(AppConstants.API.GET_BRANDS, body, {headers: this.headers})
  }

  updateBrand(id: string, body: any) {
    return this.http.put<Brand>(AppConstants.API.GET_BRANDS + '/' + id, body, {headers: this.headers})
  }

  searchUsers(search: string) {
    return this.http.get<RegularUser[]>(AppConstants.API.GET_REGULAR_USERS + '/' + search, {headers: this.headers})
  }

  approveTxn(id: string) {
    return this.http.put<TransactionRes>(AppConstants.API.TRANSACTIONS + '/approve/' + id, {}, {headers: this.headers});
  }

  createAdjustment(id: string, amount: number, bonus: boolean, reason: string) {
    const body = {transactionId: id, amount, adjustBonus: bonus, reason};
    return this.http.post<TransactionRes>(AppConstants.API.TRANSACTIONS + '/adjustment', body, {headers: this.headers});
  }

  createRefund(id: string, amount: number, adjustBonus: boolean, reason: string) {
    const body = {depositTransactionId: id, amount, adjustBonus}
    return this.http.post<TransactionRes>(AppConstants.API.TRANSACTIONS + '/refund', body, {headers: this.headers});
  }

  addTransaction(body: any) {
    return this.http.post<TransactionRes>(AppConstants.API.TRANSACTIONS + '/deposit', body, {headers: this.headers});
  }
}

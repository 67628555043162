<div class="modal-padding">
  <div class="row">
    <div class="col"><h1>{{title}}</h1></div>
    <div class="col-auto cursor-pointer" (click)="close()"><span class="mdi mdi-close icon-st"></span></div>
  </div>
  <hr>

  <div class="form">

    <mat-radio-group *ngIf="showCrDrFlag" [(ngModel)]="txnType">
      <mat-radio-button value="debit">Debit</mat-radio-button>
      <mat-radio-button value="credit">Credit</mat-radio-button>
    </mat-radio-group>

    <mat-form-field appearance="outline" class="w-full mt-5">
      <mat-label>{{amountType}}</mat-label>
      <input matInput [(ngModel)]="amount" (ngModelChange)="amountUpdated()">
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-full mt-3">
      <mat-label>Describe Reason</mat-label>
      <textarea matInput [(ngModel)]="reason"></textarea>
    </mat-form-field>

    <div class="row align-items-center outline-rounded">
      <div class="col">
        <mat-slide-toggle [(ngModel)]="bonus"></mat-slide-toggle>
        <span class="white-col fs-16 m-l-8">Debit Alfred's Points</span>
      </div>
    </div>

  </div>


  <button mat-raised-button class="white-btn m-t-24" (click)="submit()">{{buttonTxt}}</button>

</div>

import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";
import {Clipboard} from "@angular/cdk/clipboard";
import {Transaction} from "../models/TransactionRes";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private toast: MatSnackBar, private clipboard: Clipboard) { }

  txn: Transaction | undefined;
  data: any;

  getStatusColor(status: string): any{
    status = status.toUpperCase();
    if (status === 'CANCELLED' || status === 'CANCELLED_BY_CUSTOMER' || status === 'FAILED') {
      return {status, primary: '#f64e60', secondary: '#ffe2e5'};
    } else if (status === 'PENDING') {
      return {status, primary: '#ffa800', secondary: '#fff4de'};
    } else if (status === 'PREPARING') {
      return {status, primary: '#3699ff', secondary: '#e1f0ff'};
    } else if (status === 'READY') {
      return {status, primary: '#1bc5bd', secondary: '#c9f7f5'};
    } else if (status === 'IN TRANSIT') {
      return {status, primary: '#536162', secondary: '#f3f4ed'};
    } else if (status === 'CANCELLED' || status === 'FAILED') {
      return {status, primary: '#9d0808', secondary: '#ff6c6c'};
    } else if (status === 'ACCEPTED') {
      return {status, primary: '#1bc5bd', secondary: '#c9f7f5'};
    } else if (status === 'REJECTED') {
      return {status, primary: '#93329e', secondary: '#f8a1d1'};
    } else if (status === 'SUCCESS') {
      return {status, primary: '#1fab89', secondary: '#9df3c4'};
    } else {
      return {status, primary: '#3d84a8', secondary: '#bad7df'};
    }
  }

  /**
   * Used to display a snack bar message
   * @param msg - Message of toast/snackbar
   * @param type 0-green, 1-regular, 2-red, 3-dark
   * @param action Action button text
   * @param duration Duration of message
   */
  presentToast(msg: string, type: number = 3, action: string = 'Ok', duration: number = 3000): void {
    let css = '';
    switch (type) {
      case 0:
        css = 'green-snackbar';
        break;
      case 1:
        css = 'regular-snackbar';
        break;
      case 2:
        css = 'red-snackbar';
        break;
      case 3:
        css = 'dark-snackbar';
        break;
      default:
        css = 'regular-toast';
    }

    this.toast.open(msg, action, {panelClass: [css], duration});

  }

  public copyToClipBoard(content: string | undefined): void {
    if (content){
      this.clipboard.copy(content);
      this.toast.open('Text copied to clipboard successfully!', undefined, {duration: 1500});
    }
  }

  public truncate(value: string, len: number = 20): string{
    if (value){
      const limit = len;
      const trail = '...';
      return value.length > limit ? value.substring(0, limit) + trail : value;
    } else return  '';
  }

  public getTxnType(type: string): string{
    switch (type){
      case 'DEPOSIT' :
        return 'Deposit';
      case 'BONUS' :
        return 'Deposit Bonus';
      case 'PURCHASE' :
        return 'Payment';
      case 'REWARD' :
        return 'Reward';
      case 'MEMBERSHIP_FEE' :
        return 'Membership Fee';
      case 'ADJUSTMENT' :
        return 'Adjustment';
      case 'ALL' :
        return 'All';
      case 'REFUND' :
        return 'Refund';
      default:
      return type.toLowerCase();
    }
  }

  public getCurrency(currency: string): string{
    switch (currency){
      case 'HKD' :
        return 'HKD';
      case 'ALFREDS_POINTS' :
        return 'Points';
      case 'CORKAGE_POINTS' :
        return 'Corkage';
      case 'ALL' :
        return 'All';
      default:
      return currency.toLowerCase();
    }
  }


  getHumanDate(createdAt: any): string {
    return moment(createdAt).format('DD-MMM-YYYY [at] hh:mm A')
  }
}
